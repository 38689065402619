<template>
  <div class="container page-notifications-view">
    <div class="card">
      <div class="ui active inverted dimmer page-loader" v-if="!notification">
        <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
      </div>
      <template v-else>
        <h2 class="title m-b-15">{{ notification.name }}</h2>
        <main class="m-b-25">
          <p class="text-lg">{{ notification.description }}</p>
          <time :datetime="notification.creationDate">{{ notification.creationDate | dateFormat }}</time>
        </main>
        <footer>
          <router-link class="btn btn-primary" :to="{ name: 'notifications' }">Back to Notifications</router-link>
        </footer>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { notificationMessagesRoutine } from '@/store/modules/notificationMessages/routines'
import { getNotificationById, updateNotificationStatus } from '@/api/companyNotification'

export default {
  name: 'NotificationsView',
  data() {
    return {
      notification: null
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions({
      getAnyUnreadNotification: notificationMessagesRoutine.TRIGGER
    }),
    async init() {
      await this.fillNotification()
      // Check if EQ false! Not undefined!
      if (this.notification.isViewed === false) {
        await updateNotificationStatus(this.id)
        this.getAnyUnreadNotification()
      }
    },
    fillNotification() {
      return getNotificationById(this.id)
        .then(res => {
          this.notification = res.data
        })
        .catch(() => {
          this.notification = {}
        })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.page-notifications-view {
  flex-grow: 1;
}
</style>
